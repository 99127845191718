<template>
  <div class="main">
    <div class="card">
      <div class="title">选择查询范围</div>
      <div style="padding-left: 16px">
        时间范围：
        <el-select
          v-model="type"
          placeholder="请选择"
          @change="type == 3 ? (time = []) : (time = '')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        &emsp;
        <el-date-picker
          v-show="type < 3"
          v-model="time"
          :type="type == 1 ? 'month' : 'year'"
          placeholder="请选择"
          :format="type == 1 ? 'yyyy-MM' : 'yyyy'"
          :value-format="type == 1 ? 'yyyy-MM' : 'yyyy'"
          :clearable="false"
        ></el-date-picker>
        <el-date-picker
          v-show="type == 3"
          v-model="time"
          type="daterange"
          placeholder="请选择"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :editable="false"
          :picker-options="dateShortcutsDisableDate365Option"
          @blur="dateBlur"
        ></el-date-picker>
      </div>
    </div>
    <div class="card">
      <div class="title">选择导出的数据</div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for="item in list" :key="item.value" :label="item.value">
          {{ item.label }}
        </el-checkbox>
        <div class="flex">
          <el-checkbox :label="4">设备故障汇总</el-checkbox>
          <el-select v-model="year4" placeholder="请选择">
            <el-option label="一年内" :value="1"></el-option>
            <el-option label="2年内" :value="2"></el-option>
            <el-option label="3年内" :value="3"></el-option>
            <el-option label="全部在保" :value="''"></el-option>
          </el-select>
        </div>
        <div class="flex">
          <el-checkbox :label="5">设备故障详情</el-checkbox>
          <el-select v-model="year5" placeholder="请选择">
            <el-option label="一年内" :value="1"></el-option>
            <el-option label="2年内" :value="2"></el-option>
            <el-option label="3年内" :value="3"></el-option>
            <el-option label="全部在保" :value="''"></el-option>
          </el-select>
        </div>
      </el-checkbox-group>
    </div>
    <el-button size="medium" :loading="loading" type="primary" @click="submit">
      提交任务
    </el-button>
  </div>
</template>

<script>
import {
  dateShortcutsDisableDate365Option,
  dateBlur
} from '@/assets/js/dateShortcutSelection'
export default {
  data() {
    return {
      dateShortcutsDisableDate365Option,
      dateBlur,
      options: [
        {
          value: 1,
          label: '月'
        },
        {
          value: 2,
          label: '年'
        },
        {
          value: 3,
          label: '任意时间段（最长一年）'
        }
      ],
      type: 1,
      checkList: [],
      list: [
        {
          label: '故障统计分析',
          value: 1
        },
        {
          label: '区域工单完成情况',
          value: 2
        },
        {
          label: '超时工单详情',
          value: 3
        }
      ],
      time: this.$utils.dateFormat(new Date(), 'YYYY-mm'),
      year4: 1,
      year5: 1,
      loading: false
    }
  },
  created() {
    this.$api.workOrder.getFileList()
  },
  methods: {
    submit() {
      try {
        console.log(this.checkList, this.time)
        if (!this.time) {
          return this.$message.warning('请选择时间范围')
        }
        if (!this.checkList.length) {
          return this.$message.warning('请勾选导出的数据')
        }
        console.log(this.checkList)
        this.loading = true
        let startTime = '',
          endTime = '',
          params = {}

        if (typeof this.time == 'string') {
          if (this.time.length == 4) {
            startTime = this.time + '-01-01 00:00:00'
            endTime = this.time + '-12-31 23:59:59'
          } else {
            startTime = this.time + '-01 00:00:00'
            endTime = `${this.time}-${new Date(
              this.time.substring(0, 4),
              this.time.substring(5, 7),
              0
            ).getDate()} 23:59:59`
          }
        } else {
          startTime = this.time[0] + ' 00:00:00'
          endTime = this.time[1] + ' 23:59:59'
        }
        let promise = []
        this.checkList.forEach(async i => {
          if (i == 4 || i == 5) {
            params = {
              startTime,
              endTime,
              year: this['year' + i]
            }
          } else {
            params = {
              startTime,
              endTime
            }
          }
          let p = await this.$api.workOrder['export' + i](params)
          promise.push(p)
        })
        Promise.all(promise)
          .then(() => {
            let timer = setTimeout(() => {
              this.$message.success(
                '导出任务已提交，请稍后在下载中心查看下载情况。'
              )
              this.checkList = []
              this.loading = false
              clearTimeout(timer)
            }, 1200)
          })
          .catch(() => {})
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding: 15px;
  background: #fff;
  .card {
    margin: 30px 0;
    .title {
      margin: 12px 0;
      font-weight: bold;
    }
  }
  .flex {
    display: flex;
  }
  .el-checkbox {
    display: block;
    margin: 12px 16px;
  }
}
</style>
